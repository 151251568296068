import React from "react";
import _ from "lodash";
import moment from "moment-strftime";

import safePrefix from "../utils/safePrefix";

import { Link } from "gatsby";

export default class PostIndex extends React.Component {
  render() {
    let display_posts = _.get(this.props, "pageContext.pages")
      .filter(page => page.relativeDir === "posts")
      .slice(0, _.get(this.props, "section.num_posts_displayed"));
    return (
      <section id={_.get(this.props, "section.section_id")} className="posts">
        <div className="postindex">
          {_.map(
            _.orderBy(display_posts, "frontmatter.date", "desc"),
            (post, post_idx) => (
              <div key={post_idx} className="col-12">
                <div className="postindex__item">
                  <div className="copy">
                    <Link to={safePrefix(_.get(post, "url"))}>
                      <h2 className="p-heading--three postindex__heading">
                        {_.get(post, "frontmatter.title")}
                      </h2>
                    </Link>
                    <h3 className="p-heading--six">
                      Published on{" "}
                      {moment(_.get(post, "frontmatter.date")).strftime(
                        "%B %d, %Y"
                      )}
                    </h3>
                    <p className="summary">
                      {_.get(post, "frontmatter.subtitle")}
                    </p>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </section>
    );
  }
}
