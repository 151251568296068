import React from "react";

import { Layout } from "../components/index";
import PostIndex from "../components/PostIndex";
import HeroTitle from "../components/HeroTitle";

export default class Blog extends React.Component {
  render() {
    return (
      <Layout {...this.props}>
        <section className="page">
          <div className="p-strip is-deep">
            <HeroTitle {...this.props} />
            <PostIndex pageContext={this.props.pageContext} />
          </div>
        </section>
      </Layout>
    );
  }
}
